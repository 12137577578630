/* eslint-disable react/prop-types */
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';

import AddIcon from '@mui/icons-material/Add';
import { useState, useEffect } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import ProductDetail from '../ProductDetail';
import Toaster from '../../../components/shared/Toaster';
import useMiddlewares from '../../../core/useMiddleware';
import ShowImgVideo from './ShowImgVideo';

const useStyles = makeStyles((theme) => ({
  center: {
    textAlign: 'center',
  },
  productImage: {
    width: '45px',
    height: '56px',
    marginRight: '15px',
    borderRadius: '4px',
    objectFit: 'cover',
  },
  productVideo: {
    width: '45px',
    height: '56px',
    marginRight: '15px',
    borderRadius: '4px',
    objectFit: 'cover',
  },
  productList: {
    overflowY: 'scroll',
    height: '300px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      height: '300px',
    },
  },
  dialogHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    margin: '4px 0px',
  },
  dialogPaper: {
    borderRadius: '25px',
    minHeight: '70vh',
  },
  storename: {
    fontWeight: 700,
    color: '#292929',
  },
  strikeThrough: {
    textDecoration: 'line-through',
    opacity: 0.33,
  },
  onSale: {
    color: '#F26B1D',
  },
  videoPlayer: {
    '& > *': {
      objectFit: 'cover',
    },
  },
}));

const ProductList = ({
  handleProductAssign = null,
  nextActionBtn,
  productList,
  selectedProductIds,
  showDialog,
  showStoreName,
  navigate,
  fromPost,
  productCurrency,
}) => {
  const classes = useStyles();
  const dense = true;
  const [productArr, setProductArr] = useState([]);
  const router = useRouter();
  const [openProductModal, setOpenProductModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({
    id: '',
    name: '',
    slug: '',
  });
  const { isVendor } = useMiddlewares();

  useEffect(() => {
    setProductArr(selectedProductIds);
  }, [selectedProductIds]);

  const addProductArray = (product) => {
    handleProductAssign([...productArr, product.id]);
    setProductArr([...productArr, product.id]);
  };

  const removeProductArray = (product) => {
    handleProductAssign(productArr.filter((prod) => prod !== product.id));
    setProductArr(productArr.filter((prod) => prod !== product.id));
  };

  const editProduct = (product) => {
    fromPost && navigate();
    router.push(`/products/edit/${product.id}`);
  };

  const goToProdutDetail = (product) => {
    // if (!isLoggedIn) return router.push('/login');
    if (isVendor) {
      router.push(`/products/edit/${product.id}`);
    } else {
      setOpenProductModal(true);
      setSelectedProduct(product);
    }
  };

  const handleModalClose = () => {
    setOpenProductModal(false);
  };

  return (
    <>
      <Toaster />
      <div className={nextActionBtn ? '' : classes.productList}>
        <List dense={dense} style={{ paddingRight: '20px' }}>
          {productList &&
            productList.map((product, i) => {
              return (
                <ListItem
                  disableGutters
                  key={i}
                  sx={{
                    marginRight: '18px',
                  }}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    {
                      showDialog
                        ? goToProdutDetail(product)
                        : editProduct(product);
                    }
                  }}
                >
                  <ShowImgVideo product={product} />
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography variant="h5">{product.name}</Typography>
                    }
                    secondary={
                      <>
                        <Typography variant="h6" component="h6">
                          {product?.sale_price && (
                            <span>
                              {productCurrency?.symbol}
                              {product?.sale_price}{' '}
                              {product?.store?.currency ||
                                productCurrency?.value}
                            </span>
                          )}{' '}
                          {product?.price > 0 && (
                            <span
                              className={
                                !!product?.is_on_sale &&
                                product?.sale_price &&
                                classes.strikeThrough
                              }
                            >
                              {productCurrency?.symbol}
                              {product?.price}{' '}
                              {product?.store?.currency ||
                                productCurrency?.value}{' '}
                            </span>
                          )}
                        </Typography>
                        {!!product?.is_on_sale && product?.sale_price && (
                          <Typography
                            variant="h6"
                            component="h6"
                            className={classes.onSale}
                          >
                            On sale
                          </Typography>
                        )}

                        {showStoreName && (
                          <Typography variant="h6" component="h6">
                            by{' '}
                            <span className={classes.storename}>
                              {' '}
                              {product?.store?.username}{' '}
                            </span>
                          </Typography>
                        )}
                      </>
                    }
                  />

                  {nextActionBtn ? (
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="add"
                        style={{ color: '#000000' }}
                        onClick={() => {
                          {
                            showDialog
                              ? goToProdutDetail(product)
                              : editProduct(product);
                          }
                        }}
                      >
                        <ArrowForwardIosIcon fontSize="small" />
                      </IconButton>
                    </ListItemSecondaryAction>
                  ) : (
                    <ListItemSecondaryAction>
                      {productArr.includes(product.id) ? (
                        <IconButton
                          edge="end"
                          aria-label="add"
                          style={{ color: '#000000' }}
                          onClick={() => {
                            removeProductArray(product);
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      ) : (
                        <IconButton
                          edge="end"
                          aria-label="add"
                          style={{ color: '#000000' }}
                          onClick={() => {
                            addProductArray(product);
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      )}
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              );
            })}
        </List>

        <Dialog
          onClose={handleModalClose}
          aria-labelledby="customized-dialog-title"
          open={openProductModal}
          PaperProps={{
            style: {
              width: '343px',
              overflowX: 'hidden',
              height: 'auto',
              borderRadius: '20px',
              paddingBottom: '20px',
            },
          }}
        >
          <div className={classes.dialogPaper}>
            <div className={classes.dialogHeader}>
              <IconButton
                aria-label="back"
                style={{ color: '#000000' }}
                onClick={handleModalClose}
              >
                <ArrowBackIos />
              </IconButton>
              <Typography variant="h4">{selectedProduct.name}</Typography>
              <IconButton
                style={{ color: '#000000' }}
                onClick={handleModalClose}
              >
                <ClearIcon />
              </IconButton>
            </div>
            <ProductDetail
              productSlug={selectedProduct.slug}
              popUp={true}
              handleModalClose={handleModalClose}
            />
          </div>
        </Dialog>
      </div>
    </>
  );
};

ProductList.propTypes = {
  handleProductAssign: PropTypes.func || PropTypes.string,
  nextActionBtn: PropTypes.bool,
  productList: PropTypes.array,
  selectedProductIds: PropTypes.array,
  showDialog: PropTypes.bool,
  showStoreName: PropTypes.bool,
  navigate: PropTypes.func,
  fromPost: PropTypes.bool,
  productCurrency: PropTypes.shape({
    symbol: PropTypes.string,
    value: PropTypes.string,
  }),
};

export default ProductList;
