import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((_theme) => ({
  productImage: {
    width: '45px',
    height: '56px',
    marginRight: '15px',
    borderRadius: '4px',
    objectFit: 'cover',
  },
  productVideo: {
    width: '45px',
    height: '56px',
    marginRight: '15px',
    borderRadius: '4px',
    objectFit: 'cover',
  },
  videoPlayer: {
    '& > *': {
      objectFit: 'cover',
    },
  },
}));

interface ShowImgVideoProps {
  product: {
    image_url: string;
  };
}

const ShowImgVideo = ({ product }: ShowImgVideoProps) => {
  const classes = useStyles();
  return <img className={classes.productImage} src={product.image_url} />;
};

export default ShowImgVideo;
