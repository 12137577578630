import gql from 'graphql-tag';

export const GET_WISH_LIST = gql`
  query ($page: Int, $pageSize: Int) {
    getProductWishlist(page: $page, pageSize: $pageSize) {
      id
      name
      price
      slug
      is_on_sale
      sale_price
      medias {
        id
        public_url
        file_type
        images {
          vendor_products
        }
      }
      store {
        id
        business_name
        profile_image
        currency
      }
      slug
    }
  }
`;

export const CHECK_WISHLIST_EMPTY = gql`
  query {
    checkIfWishlistIsEmpty {
      status
    }
  }
`;

export const CHECK_IF_POST_IS_BOOKMARKED = gql`
  query checkIfPostIsBookmarked($postId: ID!) {
    checkIfPostIsBookmarked(postId: $postId) {
      status
    }
  }
`;
