import React, { useState, useEffect } from 'react';

import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useMutation, useApolloClient } from '@apollo/client';
import EventBus from '../../../core/EventBus';
import {
  BOOKMARK_POST,
  REMOVE_BOOKMARK_POST,
} from '../../../graphQL/mutations/wishlist';
import { CHECK_IF_POST_IS_BOOKMARKED } from '../../../graphQL/queries/wishlist';
import { BookmarkBorder, Bookmark } from '@mui/icons-material';
const useStyles = makeStyles({
  iconButton: {
    padding: '6px',
    '&:hover': {
      backgroundColor: 'none !important',
    },
  },
});

const BookmarkButton = ({ post, isLoggedIn, isSinglePost }) => {
  const router = useRouter();
  const client = useApolloClient();
  const classes = useStyles();

  const [isBookMarked, setBookmark] = useState(false);

  const [addBookmarkPost] = useMutation(BOOKMARK_POST);
  const [removeBookmarkPost] = useMutation(REMOVE_BOOKMARK_POST);

  useEffect(() => {
    if (isSinglePost && post.id) {
      checkIfPostIsBookmarked(post.id);
    } else {
      setBookmark(post.bookmarked);
    }
  }, [post]);

  const checkIfPostIsBookmarked = async (postId) => {
    try {
      const { data } = await client.query({
        query: CHECK_IF_POST_IS_BOOKMARKED,
        variables: { postId },
      });

      setBookmark(data.checkIfPostIsBookmarked.status);

      return data;
    } catch (err) {
      return;
    }
  };

  const handleAddRemoveBookmarkPost = (postId) => {
    if (isLoggedIn) {
      setBookmark(!isBookMarked);
      if (isBookMarked) {
        removeBookmarkPost({ variables: { postId: postId } });
        EventBus.emit('WishListRemoved', true);
      } else {
        addBookmarkPost({ variables: { postId: postId } });
        EventBus.emit('wishListIsEmpty', false);
      }
    } else {
      router.push('/login');
    }
  };

  return (
    <IconButton
      classes={{ root: classes.iconButton }}
      aria-label="Add to wishlist"
      title="Add to wishlist"
      onClick={() => handleAddRemoveBookmarkPost(post.id)}
    >
      {isBookMarked ? (
        <Bookmark style={{ color: 'orange' }} />
      ) : (
        <BookmarkBorder />
      )}
    </IconButton>
  );
};

BookmarkButton.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.string,
    bookmarked: PropTypes.bool,
  }),
  isLoggedIn: PropTypes.bool,
  isSinglePost: PropTypes.bool,
};

export default BookmarkButton;
