import React, { useState, useEffect } from 'react';
import {
  Avatar,
  CardHeader,
  IconButton,
  Typography,
  Card,
  CardContent,
  CardActions,
  MenuItem,
  Menu,
  Fab,
  Chip,
  Dialog,
  List,
  ListItem,
  ListItemText,
  Popover,
  Snackbar,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  SendOutlined,
  LinkOutlined,
  Twitter,
  Facebook,
  MoreHoriz,
  ShareOutlined,
  WhatsApp,
} from '@mui/icons-material';

import { TiStarburst } from 'react-icons/ti';
import { red } from '@mui/material/colors';
import { useMutation } from '@apollo/client';
import theme from '../../../assets/jss/theme';
import ProductList from '../product/ProductList';
import ServiceList from '../services/ServiceList';
import ClearIcon from '@mui/icons-material/Clear';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { FaThumbtack } from 'react-icons/fa';
import { PIN_POST, DELETE_POST } from '../../graphQL/mutations/post';
import EventBus from '../../core/EventBus';
import { isMobile } from 'react-device-detect';
import BookmarkButton from './Post/BookmarkButton';
import ImgOrVideo from './Post/ImgOrVIdeo';
import { CURRENCY_LOCATIONS } from '../../core/constants/constant';
import { PostContent } from './Post/PostContent';
import { addHttps } from '../../utils/addHttps';

const hostUrl = process.env.API_URL;

const useStyles = makeStyles({
  root: {
    position: 'relative',
    boxShadow: 'none',
    marginLeft: '-16px',
    marginRight: '-16px',
  },
  growing: {
    flexGrow: 1,
  },

  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  title: {
    fontWeight: 600,
  },
  content: {
    padding: '5px 16px 0px 16px',
  },
  sendButton: {
    transform: 'rotate(-45deg)',
    marginBottom: '5px',
  },
  cardAction: {
    padding: '0px',
  },
  chip: {
    backgroundColor: '#fff',
    border: '1px solid gray',
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
  verified: {
    backgroundColor: '#F26B1D',
    marginRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
  },
  checkIcon: {
    color: '#000 !important',
  },
  dialogPaper: {
    minWidth: '300px',
    minHeight: '70vh',
    maxHeight: '70vh',
    borderRadius: '25px',
  },
  ProductTite: {
    padding: '8px 0 0 14px',
  },
  viewProductsBtn: {
    display: 'flex',
    verticalAlign: 'middle',
    justifyContent: 'center',
    margin: '20px 0px',
  },
  dialogHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    margin: '15px 10px',
  },
  actions: {
    bottom: '0px !important',
    left: '0px !important',
    position: 'fixed',
    maxHeight: 'fit-content',
    maxWidth: 'inherit',
    top: 'initial !important',
    borderTopRightRadius: '25px',
    borderTopLeftRadius: '25px',
  },
  itemText: {
    textAlign: 'center',
  },
  listItem: {
    borderBottom: '1px solid #e1e1e1',
  },
  deleteConfirmText: {
    textAlign: 'center',
  },
  pin: {
    position: 'absolute',
    top: '14%',
    right: theme.spacing(5),
    backgroundColor: '#ffffff',
    zIndex: 1200,
  },
  pointer: {
    cursor: 'pointer',
  },
  avatarImage: {
    cursor: 'pointer',
    marginRight: '15px',
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  share: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    transitionDuration: '0s !important',
  },
  shareButton: {
    color: '#000',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
});

const Post = ({
  post,
  showSetting,
  isLoggedIn,
  isVendor,
  isSinglePost = false,
}) => {
  const classes = useStyles();
  const router = useRouter();
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const [pinPost] = useMutation(PIN_POST);
  const [deletePost] = useMutation(DELETE_POST);

  const [copySuccess, setCopySuccess] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const [openProductModal, setOpenProductModal] = useState(false);
  const [openServiceModal, setOpenServiceModal] = useState(false);
  const [openConfirmationPopover, setConfirmationOpenPopover] = useState(false);
  const [productCurrency, setProductCurrency] = useState({
    value: 'AUD',
    symbol: '$',
  });

  useEffect(() => {
    if (post && post?.store?.currency) {
      const [currency] = CURRENCY_LOCATIONS.filter(
        (location) => location.value == post?.store?.currency,
      );
      setProductCurrency({
        value: currency.value,
        symbol: currency.symbol,
      });
    }
  }, [post]);

  const handleClick = (event, title, id) => {
    if (isMobile) {
      shareOnMobile(event, title, id);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProductOpen = () => {
    setOpenProductModal(true);
  };

  const handleServiceOpen = () => {
    setOpenServiceModal(true);
  };

  const handleServiceModalClose = () => {
    setOpenServiceModal(false);
  };

  const handleModalClose = () => {
    setOpenProductModal(false);
  };

  const goToStoreProfile = (post) => {
    if (post?.store?.username) {
      router.push('/store/' + post?.store?.username);
    }

    if (post?.organisation?.username) {
      router.push('/store/' + post?.organisation?.username);
    }
  };

  const showPopOver = () => {
    setOpenPopover(true);
  };

  const onClickPinUnpinPost = (postId) => {
    pinPost({
      variables: { id: postId },
    }).then((res) => {
      if (res) {
        setOpenPopover(false);
        EventBus.emit('loadPostsByStore', true);
      }
    });
  };

  const showDeleteConfirmationPopover = () => {
    setOpenPopover(false);
    setConfirmationOpenPopover(true);
  };

  const handleDeletePost = (postId) => {
    deletePost({
      variables: { id: postId },
    }).then((res) => {
      if (res) {
        setConfirmationOpenPopover(false);
        router.back();
        //EventBus.emit('loadPostsByStore', true);
      }
    });
  };

  const handleEditPost = (postId) => {
    router.push('/posts/edit/' + postId);
  };

  const handleClosePopOver = () => {
    setOpenPopover(false);
  };

  const handleCloseConfirmPopOver = () => {
    setConfirmationOpenPopover(false);
  };

  const shareOnMobile = (event, title, id) => {
    const url = hostUrl + '/posts/' + id;
    if (navigator.share) {
      navigator
        .share({
          title: `${title}`,
          url: `${url}`,
        })
        .then(() => {
          //console.log('Thanks for sharing');
        })
        .catch(console.error);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const shareOnFacebook = (description, id) => {
    const url = hostUrl + '/posts/' + id;
    const fbShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${description}&display=popup`;
    window.open(fbShareUrl, '_blank', 'height=600,width=600');
    setAnchorEl(null);
  };

  const shareOnTwitter = (title, id) => {
    const url = hostUrl + '/posts/' + id;
    const twitterShareUrl = `https://www.twitter.com/intent/tweet?text=${title}&url=${url}`;
    window.open(twitterShareUrl, '_blank', 'height=600,width=600');
    setAnchorEl(null);
  };

  const shareOnMessage = (title, id) => {
    const url = hostUrl + '/posts/' + id;
    const whatsappShareUrl = `https://wa.me/?text="Check out this, ${title} ${url}"`;
    window.open(whatsappShareUrl, '_blank', 'height=600,width=600');
    setAnchorEl(null);
  };

  const shareOnBookmark = (id) => {
    const url = hostUrl + '/posts/' + id;
    navigator.clipboard.writeText(url);
    setCopySuccess(true);
  };

  const handleCloseSnackbar = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setCopySuccess(false);
  };

  const getProfileImage = () => {
    if (post?.organisation?.profile_image) {
      return post?.organisation?.profile_image;
    }

    return post?.store?.profile_image;
  };

  const getProfileName = () => {
    if (post?.stores && post?.stores.length > 0) {
      const profileName =
        post?.store?.business_name +
        ' and ' +
        post?.stores.map((store) => store.business_name).join(', ');

      const TEXT_LIMIT = 40;
      if (profileName.length >= TEXT_LIMIT) {
        return profileName.substring(0, TEXT_LIMIT) + '...';
      }

      return profileName;
      // : new Str().concat(end)
    }

    if (post?.organisation?.organisation_name == post?.store?.business_name) {
      return post?.store?.business_name;
    }
    return [post?.organisation?.organisation_name, post?.store?.business_name]
      .filter((name) => name != null)
      .join(' - ');
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        autoHideDuration={1000}
        open={copySuccess}
        onClose={handleCloseSnackbar}
        message="Link copied!"
      />
      <Card className={classes.root}>
        <CardHeader
          avatar={
            <Avatar
              alt="store img"
              src={getProfileImage()}
              className={classes.pointer}
              onClick={() => {
                goToStoreProfile(post);
              }}
            />
          }
          title={
            <>
              <Typography
                className={classes.pointer}
                variant="h4"
                onClick={() => {
                  goToStoreProfile(post);
                }}
              >
                {getProfileName()}
                {/* <IconButton
                  title="View Ambassdors"
                  aria-label="delete"
                  size="small"
                >
                  <ArrowDropDown />
                </IconButton> */}
              </Typography>
              {/* <AvatarGroup>
                {post?.stores && post?.stores.map((store) => {
                  return (
                    <Avatar
                      alt="Remy Sharp"
                      className={classes.small}
                      src={store.profile_image}
                    />
                  );
                  })}
              </AvatarGroup> */}
            </>
          }
          action={
            showSetting &&
            isVendor && (
              <IconButton aria-label="settings" onClick={() => showPopOver()}>
                <MoreHoriz />
              </IconButton>
            )
          }
        />

        <ImgOrVideo
          post={post}
          handleProductOpen={handleProductOpen}
          handleServiceOpen={handleServiceOpen}
        />

        {post?.pinned && (
          <Fab
            color="info"
            className={classes.pin}
            size="small"
            aria-label="Pin or unpin product"
            onClick={() => onClickPinUnpinPost(post.id)}
          >
            <FaThumbtack size="15" />
          </Fab>
        )}

        <CardActions
          className={classes.cardAction}
          disableSpacing
          style={{
            marginLeft: '6px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ padding: '6px' }}>
            {post?.affiliate_link && (
              <a
                target="_blank"
                href={addHttps(post?.affiliate_link)}
                rel="noreferrer"
              >
                <IconButton
                  aria-label={post?.affiliate_link}
                  title={post?.affiliate_link}
                  //onClick={(event) => handleClick(event, post?.caption, post?.id)}
                >
                  <LinkOutlined className={classes.sendButton} />
                </IconButton>
              </a>
            )}

            <IconButton
              aria-label="Share"
              title="Share"
              onClick={(event) => handleClick(event, post?.caption, post?.id)}
            >
              <SendOutlined className={classes.sendButton} />
            </IconButton>
          </div>
          <BookmarkButton
            post={post}
            isLoggedIn={isLoggedIn}
            isSinglePost={isSinglePost}
          />
          <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            className={classes.share}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            PaperProps={{
              style: {
                backgroundColor: '#f7f7f7',
                boxShadow: 'none',
              },
            }}
          >
            <MenuItem disableRipple className={classes.share}>
              <IconButton
                onClick={() => shareOnFacebook(post?.caption, post?.id)}
                aria-label="Facebook"
                className={classes.shareButton}
              >
                <Facebook fontSize="small" />
              </IconButton>
            </MenuItem>
            <MenuItem disableRipple className={classes.share}>
              <IconButton
                onClick={() => shareOnTwitter(post?.caption, post?.id)}
                aria-label="Twitter"
                className={classes.shareButton}
              >
                <Twitter fontSize="small" />
              </IconButton>
            </MenuItem>
            <MenuItem disableRipple className={classes.share}>
              <IconButton
                onClick={() => shareOnMessage(post?.caption, post?.id)}
                aria-label="Message"
                className={classes.shareButton}
              >
                <WhatsApp fontSize="small" />
              </IconButton>
            </MenuItem>
            <MenuItem disableRipple className={classes.share}>
              <IconButton
                onClick={() => shareOnBookmark(post?.id)}
                aria-label="Share"
                className={classes.shareButton}
              >
                <ShareOutlined fontSize="small" />
              </IconButton>
            </MenuItem>
          </Menu>
        </CardActions>
        <CardContent className={classes.content}>
          <PostContent displayContent={post?.display_caption} />
        </CardContent>
        {post?.affiliate_link && (
          <a
            target="_blank"
            href={addHttps(post?.affiliate_link)}
            rel="noreferrer"
            className={classes.content}
            style={{ color: '#3498db' }}
          >
            {post?.affiliate_link.substring(0, 30) + '... '}
          </a>
        )}

        <CardContent className={classes.content}>
          {post.tags.map((tag, index) => (
            <Chip
              key={index}
              size="small"
              className={tag.verified ? classes.verified : classes.chip}
              avatar={
                tag.verified ? (
                  <TiStarburst className={classes.checkIcon} />
                ) : (
                  <></>
                )
              }
              label={tag.name}
            />
          ))}
        </CardContent>
      </Card>
      <Dialog
        onClose={handleModalClose}
        aria-labelledby="customized-dialog-title"
        open={openProductModal}
        PaperProps={{
          style: {
            width: '343px',
            height: 'auto',
            borderRadius: '20px',
            paddingBottom: '20px',
          },
        }}
      >
        <div className={classes.dialogPaper}>
          <div className={classes.dialogHeader}>
            <Avatar
              alt="store img"
              className={classes.avatarImage}
              src={getProfileImage()}
              onClick={() => {
                goToStoreProfile(post);
              }}
            />
            <Typography
              variant="h4"
              className={classes.pointer}
              onClick={() => {
                goToStoreProfile(post);
              }}
            >
              {post?.store?.business_name}
            </Typography>
            <IconButton
              style={{ color: '#000000' }}
              onClick={() => {
                handleModalClose();
              }}
            >
              <ClearIcon />
            </IconButton>
          </div>
          {post.products.length > 0 ? (
            <div>
              <Typography variant="body1" className={classes.ProductTite}>
                Products shown
              </Typography>
              <div style={{ margin: theme.spacing(0, 2) }}>
                <ProductList
                  nextActionBtn={true}
                  productList={post.products}
                  productCurrency={productCurrency}
                  showDialog={true}
                  showStoreName={false}
                />
              </div>
            </div>
          ) : (
            <Typography variant="body1" className={classes.ProductTite}>
              Products Not Available
            </Typography>
          )}
        </div>
        {/* <div className={classes.viewProductsBtn}>
          <Button style={{ borderColor: '#000000' }} variant="outlined">
            View all products
          </Button>
        </div> */}
      </Dialog>

      <Dialog
        onClose={handleModalClose}
        aria-labelledby="customized-dialog-title"
        open={openServiceModal}
        PaperProps={{
          style: {
            width: '343px',
            height: 'auto',
            borderRadius: '20px',
            paddingBottom: '20px',
          },
        }}
      >
        <div className={classes.dialogPaper}>
          <div className={classes.dialogHeader}>
            <Avatar
              alt="store img"
              className={classes.avatarImage}
              src={getProfileImage()}
              onClick={() => {
                goToStoreProfile(post);
              }}
            />
            <Typography
              variant="h4"
              className={classes.pointer}
              onClick={() => {
                goToStoreProfile(post);
              }}
            >
              {post?.store?.business_name}
            </Typography>
            <IconButton
              style={{ color: '#000000' }}
              onClick={() => {
                handleServiceModalClose();
              }}
            >
              <ClearIcon />
            </IconButton>
          </div>
          {post?.storeServices && post?.storeServices.length > 0 ? (
            <div>
              <Typography variant="body1" className={classes.ProductTite}>
                Services shown
              </Typography>
              <div style={{ margin: theme.spacing(0, 2) }}>
                <ServiceList
                  productList={post?.storeServices}
                  productCurrency={productCurrency}
                  store={post?.store}
                />
              </div>
            </div>
          ) : (
            <Typography variant="body1" className={classes.ProductTite}>
              Services Not Available
            </Typography>
          )}
        </div>
        {/* <div className={classes.viewProductsBtn}>
          <Button style={{ borderColor: '#000000' }} variant="outlined">
            View all products
          </Button>
        </div> */}
      </Dialog>

      <Popover
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClosePopOver}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        style={{
          maxHeight: '200px',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: { width: '100%' },
          classes: { root: classes.actions },
        }}
      >
        <List component="nav" aria-label="actions">
          <ListItem
            className={classes.listItem}
            button
            onClick={() => handleEditPost(post.id)}
          >
            <ListItemText className={classes.itemText} primary="Edit post" />
          </ListItem>
          <ListItem
            className={classes.listItem}
            button
            onClick={() => onClickPinUnpinPost(post.id)}
          >
            <ListItemText
              className={classes.itemText}
              primary={post?.pinned ? 'Unpin post' : 'Pin post'}
            />
          </ListItem>
          <ListItem className={classes.listItem} button>
            <ListItemText
              className={classes.itemText}
              style={{ color: 'red' }}
              primary="Delete post"
              onClick={showDeleteConfirmationPopover}
            />
          </ListItem>
          <ListItem
            className={classes.listItem}
            button
            onClick={handleClosePopOver}
          >
            <ListItemText className={classes.itemText} primary="Cancel" />
          </ListItem>
        </List>
      </Popover>

      <Popover
        open={openConfirmationPopover}
        anchorEl={anchorEl}
        onClose={handleCloseConfirmPopOver}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: { width: '100%' },
          classes: { root: classes.actions },
        }}
      >
        <Typography className={classes.deleteConfirmText}>
          Are you sure you want to delete the post?
        </Typography>
        <List aria-label="actions">
          <ListItem
            className={classes.listItem}
            button
            onClick={() => handleDeletePost(post.id)}
          >
            <ListItemText
              className={classes.itemText}
              style={{ color: 'red' }}
              primary="Delete"
            />
          </ListItem>

          <ListItem
            className={classes.listItem}
            button
            onClick={handleCloseConfirmPopOver}
          >
            <ListItemText className={classes.itemText} primary="Cancel" />
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};

Post.propTypes = {
  post: PropTypes.shape({
    store: PropTypes.shape({
      profile_image: PropTypes.string,
      name: PropTypes.string,
      business_name: PropTypes.string,
      username: PropTypes.string,
      currency: PropTypes.string,
    }),
    stores: PropTypes.arrayOf(
      PropTypes.shape({
        profile_image: PropTypes.string,
        name: PropTypes.string,
        business_name: PropTypes.string,
        username: PropTypes.string,
        currency: PropTypes.string,
      }),
    ),
    organisation: PropTypes.shape({
      organisation_name: PropTypes.string,
      username: PropTypes.string,
      profile_image: PropTypes.string,
    }),
    id: PropTypes.string,
    pinned: PropTypes.bool,
    bookmarked: PropTypes.bool,
    caption: PropTypes.string,
    display_caption: PropTypes.string,
    affiliate_link: PropTypes.string,
    medias: PropTypes.arrayOf(
      PropTypes.shape({
        public_url: PropTypes.string,
        file_type: PropTypes.string,
        images: PropTypes.shape({
          explore_posts: PropTypes.string,
          explore_posts_img: PropTypes.string,
          buyer_following_posts: PropTypes.string,
          explore_posts_img_placeholder: PropTypes.string,
        }),
      }),
    ),
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        name: PropTypes.string,
      }),
    ),
    products: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    storeServices: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
  }),
  showSetting: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  isVendor: PropTypes.bool,
  isSinglePost: PropTypes.bool,
};

export default Post;
