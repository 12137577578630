import { gql } from '@apollo/client';

export const CHECK_USER_EMAIL = gql`
  mutation emailUniqueValidation($email: String) {
    emailUniqueValidation(email: $email) {
      emailAlreadyExists
    }
  }
`;

export const UPDATE_AUTH_DETAILS = gql`
  mutation ($data: UpdateProfileInput!) {
    updateProfile(input: $data) {
      email
    }
  }
`;

export const CREATE_PROFILE = gql`
  mutation ($data: CreateProfileInput!) {
    createProfile(input: $data) {
      age
    }
  }
`;

export const DELETE_ACCOUNT = gql`
  mutation deleteAccount {
    deleteAccount {
      email
    }
  }
`;

export const CHANGE_PASSWORD_EMAIL = gql`
  mutation changePasswordSuccessfully {
    changePasswordSuccessfully {
      email
    }
  }
`;

export const CONFIRM_EMAIL = gql`
  mutation confirmedEmailSuccessfully($username: String!) {
    confirmedEmailSuccessfully(username: $username) {
      email
    }
  }
`;

export const VERITFY_EMAIL = gql`
  mutation verifyEmail {
    verifyEmail {
      status
    }
  }
`;

export const UPLOAD_AVATAR_TEMPORARY = gql`
  mutation uploadAvatarTemporary($file: Upload!) {
    uploadAvatarTemporary(file: $file) {
      image_url
    }
  }
`;

export const UPLOAD_AVATAR = gql`
  mutation uploadAvatar($file: Upload!) {
    uploadAvatar(file: $file) {
      image_url
    }
  }
`;
