import { gql } from '@apollo/client';

export const CREATE_POST = gql`
  mutation createPost($data: PostInput!) {
    createPost(input: $data) {
      caption
      id
    }
  }
`;

export const PIN_POST = gql`
  mutation pinOrUnPinPost($id: ID!) {
    pinOrUnPinPost(id: $id) {
      id
    }
  }
`;

export const DELETE_POST = gql`
  mutation deletePost($id: ID!) {
    deletePost(id: $id) {
      id
    }
  }
`;

export const UPDATE_POST = gql`
  mutation updatePost($id: ID!, $data: PostInput!) {
    updatePost(id: $id, input: $data) {
      caption
      id
    }
  }
`;
