import PropTypes from 'prop-types';
import Carousel from 'react-material-ui-carousel';
import SingleImgOrVideo from './SingleImgOrVideo';

const ImgOrVideo = ({ post, handleProductOpen, handleServiceOpen }) => {
  if (post.medias.length == 0) {
    return null;
  }

  if (post && post.medias.length == 1) {
    return (
      <SingleImgOrVideo
        media={post.medias[0]}
        products={post.products}
        storeServices={post.storeServices}
        handleProductOpen={handleProductOpen}
        handleServiceOpen={handleServiceOpen}
      />
    );
  }

  return (
    <Carousel
      animation="slide"
      indicators={true}
      interval={500}
      navButtonsAlwaysVisible={true}
      navButtonsAlwaysInvisible={false}
      cycleNavigation={true}
      fullHeightHover={false}
    >
      {post?.medias.map((media: any) => {
        return (
          <SingleImgOrVideo
            key={media.id}
            media={media}
            products={post.products}
            storeServices={post.storeServices}
            handleProductOpen={handleProductOpen}
            handleServiceOpen={handleServiceOpen}
          />
        );
      })}
    </Carousel>
  );
};

ImgOrVideo.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.string,
    medias: PropTypes.arrayOf(
      PropTypes.shape({
        public_url: PropTypes.string,
        file_type: PropTypes.string,
        images: PropTypes.shape({
          explore_posts: PropTypes.string,
          explore_posts_img: PropTypes.string,
          buyer_following_posts: PropTypes.string,
          explore_posts_img_placeholder: PropTypes.string,
        }),
      }),
    ),
    storeServices: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
    products: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      }),
    ),
  }),
  handleProductOpen: PropTypes.func,
  handleServiceOpen: PropTypes.func,
};

export default ImgOrVideo;
