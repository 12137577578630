import * as DOMPurify from 'dompurify';

const defaultOptions = {
  ALLOWED_TAGS: ['a'],
  ALLOWED_ATTR: ['href'],
};

export const sanitizeHtml = (dirty, options) => {
  if (typeof window === 'undefined') {
    return { __html: dirty };
  }
  return {
    __html: DOMPurify.sanitize(dirty, { ...defaultOptions, ...options }),
  };
};
