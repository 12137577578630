import gql from 'graphql-tag';

export const SEARCH_TAGS_LIST = gql`
  query ($search: String, $page: Int, $pageSize: Int) {
    tags(search: $search, page: $page, pageSize: $pageSize) {
      id
      name
      used_count
      verified
      tag_type
      authorize_store_ids
    }
  }
`;
export const SEARCH_INTERESTS_LIST = gql`
  query ($search: String, $page: Int, $pageSize: Int) {
    interests(search: $search, page: $page, pageSize: $pageSize) {
      id
      name
      used_count
      verified
      authorize_store_ids
    }
  }
`;

export const GET_VENDOR_POST_TAGS = gql`
  query ($storeId: ID!) {
    getPostTagsByStore(storeId: $storeId) {
      id
      name
      used_count
      verified
    }
  }
`;

export const GET_ORGANISATION_POST_TAGS = gql`
  query ($organisationId: ID!) {
    getPostTagsByOrganisation(organisationId: $organisationId) {
      id
      name
      used_count
      verified
    }
  }
`;

export const GET_PRODUCT_TAGS = gql`
  query ($storeId: ID!) {
    getProductTagsByStore(storeId: $storeId) {
      id
      name
      used_count
      verified
      authorize_store_ids
    }
  }
`;
