import ReactPlayer from 'react-player';
import { FILE_TYPE } from '../../../core/constants/constant';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((_theme) => ({
  productImage: {
    width: '45px',
    height: '56px',
    marginRight: '15px',
    borderRadius: '4px',
    objectFit: 'cover',
  },
  productVideo: {
    width: '45px',
    height: '56px',
    marginRight: '15px',
    borderRadius: '4px',
    objectFit: 'cover',
  },
  videoPlayer: {
    '& > *': {
      objectFit: 'cover',
    },
  },
}));

interface ShowImgVideoProps {
  product: {
    medias: Array<{
      file_type: string;
      public_url: string;
      images: {
        vendor_products: string;
        vendor_home_posts: string;
      };
    }>;
  };
}

const ShowImgVideo = ({ product }: ShowImgVideoProps) => {
  const classes = useStyles();
  if (
    product &&
    product.medias[0] &&
    product.medias[0].file_type == FILE_TYPE.VIDEO
  ) {
    return (
      <div className={classes.productVideo}>
        <ReactPlayer
          playing={false}
          playsinline={true}
          url={product && product.medias[0] && product.medias[0].public_url}
          width="45px"
          height="100%"
          className={classes.videoPlayer}
          config={{
            file: {
              attributes: {
                controlsList: 'nodownload', //<- this is the important bit
              },
            },
          }}
        />
      </div>
    );
  } else {
    const imageURL =
      (product &&
        product.medias[0] &&
        product.medias[0]?.images?.vendor_products) ||
      product.medias[0]?.images?.vendor_home_posts;
    return <img className={classes.productImage} src={imageURL} />;
  }
};

export default ShowImgVideo;
