import PropTypes from 'prop-types';
import Head from 'next/head';

const MetaDecorator = ({ title, description, imageUrl, currentUrl }) => {
  return (
    <Head>
      <title>{title}</title>
      {description && <meta name="description" content={description} />}

      <meta property="og:site_name" content="HoverIT" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:title" content={title} />
      {description && <meta property="og:description" content={description} />}
      {imageUrl && <meta property="og:image" content={imageUrl} />}
      {imageUrl && <meta property="og:image:secure_url" content={imageUrl} />}
      {currentUrl && <meta property="og:url" content={currentUrl} />}

      <meta name="twitter:site" content="HoverIT" />
      <meta name="twitter:title" content={title} />
      {description && <meta name="twitter:description" content={description} />}
      {imageUrl && <meta name="twitter:image" content={imageUrl} />}
      <meta name="twitter:card" content="summary_large_image" />
    </Head>
  );
};

MetaDecorator.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  imageUrl: PropTypes.string,
  currentUrl: PropTypes.string,
};

export default MetaDecorator;
