import Image from 'next/image';
import ReactPlayer from 'react-player/lazy';
import { FILE_TYPE } from '../../../core/constants/constant';
import { Fab } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { LocalMallOutlined, StarBorderOutlined } from '@mui/icons-material';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  media: {
    position: 'relative',
  },
  videoPlayer: {
    '& > *': {
      objectFit: 'cover',
    },
  },
  fab: {
    position: 'absolute',
    bottom: '8%',
    right: 20,
    backgroundColor: '#ffffff',
    //color: 'black',
    zIndex: 1200,
  },
  serviceIcon: {
    position: 'absolute',
    bottom: '22%',
    right: 20,
    backgroundColor: '#ffffff',
    zIndex: 1200,
  },
}));

const SingleImgOrVideo = ({
  media,
  products,
  storeServices,
  handleProductOpen,
  handleServiceOpen,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.media}>
      {media.file_type == FILE_TYPE.VIDEO ? (
        <>
          <ReactPlayer
            playing={true}
            playsinline={true}
            width="100%"
            height="375px"
            controls={true}
            light={
              // eslint-disable-next-line react/prop-types
              `${media.images.explore_posts_img}`
            }
            className={classes.videoPlayer}
            url={media.images.explore_posts}
            config={{
              file: {
                attributes: {
                  controlsList: 'nodownload', //<- this is the important bit
                },
              },
            }}
          />
          {products.length > 0 && (
            <Fab
              color="default"
              className={classes.fab}
              size="small"
              aria-label="Shop"
              title="Shop"
              onClick={handleProductOpen}
            >
              <LocalMallOutlined />
            </Fab>
          )}
          {storeServices.length > 0 && (
            <Fab
              color="info"
              className={classes.serviceIcon}
              size="small"
              aria-label="Services"
              title="Services"
              onClick={handleServiceOpen}
            >
              <StarBorderOutlined />
            </Fab>
          )}
        </>
      ) : (
        <>
          <Image
            alt="image of product"
            width={375}
            height={375}
            layout="responsive"
            objectFit="cover"
            placeholder="blur"
            loading="lazy"
            blurDataURL={media.images.explore_posts_img_placeholder}
            objectPosition="top center"
            src={media.images.explore_posts_img}
          />
          {products.length > 0 && (
            <Fab
              color="info"
              className={classes.fab}
              size="small"
              aria-label="Shop"
              title="Shop"
              onClick={handleProductOpen}
            >
              <LocalMallOutlined />
            </Fab>
          )}
          {/* {post?.storeServices && post?.storeServices.length > 0 && (
            <Fab
              color="info"
              className={classes.serviceIcon}
              size="small"
              aria-label="Services"
              title="Services"
              onClick={handleServiceOpen}
            >
              <StarBorderOutlined />
            </Fab>
          )} */}
        </>
      )}
    </div>
  );
};

SingleImgOrVideo.propTypes = {
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  storeServices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  media: PropTypes.shape({
    public_url: PropTypes.string,
    file_type: PropTypes.string,
    images: PropTypes.shape({
      explore_posts: PropTypes.string,
      explore_posts_img: PropTypes.string,
      buyer_following_posts: PropTypes.string,
      explore_posts_img_placeholder: PropTypes.string,
    }),
  }),
  handleProductOpen: PropTypes.func,
  handleServiceOpen: PropTypes.func,
};

export default SingleImgOrVideo;
