/* eslint-disable react/prop-types */
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@mui/material';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import Toaster from '../../../components/shared/Toaster';
import ShowImgVideo from './ShowImgVideo';

const ServiceList = ({ productList, store, productCurrency }) => {
  const dense = true;
  const router = useRouter();

  const goToServiceDetail = (product) => {
    router.push(`/store/${store.username}/services/${product}`);
  };

  return (
    <>
      <Toaster />
      <div>
        <List dense={dense}>
          {productList &&
            productList.map((product, i) => {
              return (
                <ListItem
                  disableGutters
                  key={i}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    goToServiceDetail(product.id);
                  }}
                >
                  <ShowImgVideo product={product} />
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography variant="h5">{product.name}</Typography>
                    }
                    secondary={
                      <>
                        <Typography variant="h6" component="h6">
                          {product?.price && (
                            <span>
                              {productCurrency?.symbol}
                              {product?.price} {productCurrency?.value}
                            </span>
                          )}{' '}
                        </Typography>
                      </>
                    }
                  />

                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="add"
                      style={{ color: '#000000' }}
                      onClick={() => {
                        goToServiceDetail(product.id);
                      }}
                    >
                      <ArrowForwardIosIcon fontSize="small" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
        </List>
      </div>
    </>
  );
};

ServiceList.propTypes = {
  productList: PropTypes.array,
  productCurrency: PropTypes.shape({
    symbol: PropTypes.string,
    value: PropTypes.string,
  }),
  store: PropTypes.shape({
    username: PropTypes.string,
  }),
};

export default ServiceList;
