import { useState } from 'react';
import { Typography } from '@mui/material';
import { sanitizeHtml } from '../../../core/sanitize/sanitizeHtml';
interface PostContentProps {
  displayContent: String;
}

export const PostContent = ({ displayContent }: PostContentProps) => {
  const limit = 140;
  const [showAll, setShowAll] = useState(false);

  if (!displayContent) {
    return null;
  }

  if (displayContent.length <= limit) {
    return (
      <Typography variant="body2" component="p">
        <div
          dangerouslySetInnerHTML={sanitizeHtml(
            displayContent.substring(0, limit) + '... ',
            {
              ALLOWED_TAGS: ['a'],
              ALLOWED_ATTR: ['href', 'style'],
            },
          )}
        />
      </Typography>
    );
  }

  const showMore = () => setShowAll(true);

  const showLess = () => setShowAll(false);

  if (showAll) {
    return (
      <p style={{ whiteSpace: 'pre-line' }}>
        <div
          dangerouslySetInnerHTML={sanitizeHtml(displayContent, {
            ALLOWED_TAGS: ['a'],
            ALLOWED_ATTR: ['href', 'style'],
          })}
        />
        <a
          href="javascript:void(0)"
          style={{ color: '#888', textDecoration: 'none' }}
          onClick={showLess}
        >
          Show less
        </a>
      </p>
    );
  }

  return (
    <p style={{ whiteSpace: 'pre-line' }}>
      <div
        dangerouslySetInnerHTML={sanitizeHtml(
          displayContent.substring(0, limit) + '... ',
          {
            ALLOWED_TAGS: ['a'],
            ALLOWED_ATTR: ['href', 'style'],
          },
        )}
      />
      <a
        href="javascript:void(0)"
        style={{ color: '#888', textDecoration: 'none' }}
        onClick={showMore}
      >
        Show more
      </a>
    </p>
  );
};
